<template>
	<div>
		<div class="text-left mb-3">
			<b>Instructions</b><br/>
			One teacher per line.<br/>
			Must input both full name and mobile number.<br/>
			Press [tab] key to separate name and mobile number.<br/>
			(OR) You may copy and paste directly from Excel.<br/>
		</div>
		<div class="name-textarea">
			<v-textarea
			autofocus
				outlined
				name="input-7-1"
				placeholder="Lakshmi	+60123333777
Ah Hock	+60123333888
Sarah	+60123333999"
				v-model="teachers"
				:rules="rules"
				@keydown="handleInput($event)"
			></v-textarea>
		</div>
	</div>
</template>

<script>
export default {
	props: ["callbackSelect", "seletedTeachers"],
	data: () => ({
		teachers: "",
		filterTeachers: [],
		name: null,
		mobile: null,
		rules: [(v) => !!v || "Name is required"],
	}),
	watch: {
		teachers: function (val) {
			this.teachers = val;
			this.filterTeachers = [];
			var filterTeachers = [];
			this.name = val.split("\n");
			this.name?.forEach((line) => {
				const values = line.split("\t");
          filterTeachers.push({ name: values[0], mobile: values[1] });
			});
			this.filterTeachers = filterTeachers;
			this.callbackSelect(this.filterTeachers);
		},
	},
	methods: {
		// handleInput(e) {
		// 	if (e.key == "Tab") {
		// 		e.preventDefault();
		// 		let startText = this.teachers.slice(0, e.target.selectionStart);
		// 		let endText = this.teachers.slice(e.target.selectionStart);
		// 		this.teachers = `${startText}\t${endText}`;
		// 		e.target.selectionEnd = e.target.selectionStart + 1;
		// 	}
		// },

handleInput(e) {
  if (e.key === "Tab") {
    e.preventDefault();
    let startText = this.teachers.slice(0, e.target.selectionStart);
    let endText = this.teachers.slice(e.target.selectionStart);
    let newText = `${startText}\t${endText}`;

    // Split newText into lines and check each line for the number of tabs
    let lines = newText.split("\n");
    let isValid = lines.every((line) => line.split("\t").length <= 2);

    if (isValid) {
      this.teachers = newText;
      e.target.selectionEnd = e.target.selectionStart + 1;
    }
  }
}

	},
	mounted() {
		for (var i = 0; i < this.seletedTeachers.length; i++) {
			if (this.seletedTeachers[i].mobile) {
				this.teachers = this.teachers + this.seletedTeachers[i].name + "\t"+ this.seletedTeachers[i].mobile+"\n";
			}
			else {
				this.teachers = this.teachers + this.seletedTeachers[i].name + "\n";
			}
		}
	},
};
</script>

<style>
.name-textarea {
	-moz-tab-size: 24 !important; /* Firefox */
	tab-size: 24 !important;
}
</style>