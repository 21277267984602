<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>New Teacher(s)</v-card-title>
      <v-stepper elevation="0" :value="step" v-if="step < 6">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="1 < step">Input</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="2 < step">Check</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="3 < step">Done</v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <TextareaFieldTeacher
              :seletedTeachers="teachers"
              :callbackSelect="getTeachers"
            ></TextareaFieldTeacher>
          </v-form>
        </div>

        <div v-else-if="step == 2" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <Preview @close="back" :teachers="teachers" />
          </v-form>
        </div>
      </div>
      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" rounded color="primary" @click="next()" :disabled="teachers.length == 0">
              Next
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="$router.go(-1)"
            >
              Cancel
            </v-btn>
          </div>
        </div>
        <div v-else-if="step == 2" class="button-content text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" :loading="api.isLoading" rounded color="primary" @click="submit()" :disabled="teachers.length < 1">
              Submit
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn
              width="75%"
              rounded
              outlined
              color="primary"
              dark
              @click="back()"
            >
              Back
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import TextareaFieldTeacher from "../../components/Form/TextareaFieldTeacher.vue";
import Preview from "@/components/Educator/Teacher/Preview";
export default {
  components: {
    Preview,
    TextareaFieldTeacher,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    valid: true,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    step: 1,
    teachers: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "Teachers",
        to: {
          name: "PageEducatorInstitutionTeacher",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
      {
        text: "New Teacher",
        to: {
          name: "PageEducatorInstitutionTeacherAdd",
          params: { id: this.$route.params.id },
        },
        exact: true,
      },
    ]
    //EOC
    //BOC
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id +
      "/teacher/addMany";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({
        name: "PageEducatorInstitutionTeacher",
        params: { id: this.$route.params.id },
      });
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    checkMobile() {
      const hasError = this.$_.some(this.teachers, (item) => {
        if (
          !item.mobile ||
          item.mobile.length < 7 ||
          item.mobile.length >= 15 ||
          !Number.isInteger(Number(item.mobile))
        ) {
          return true;
        }
        return false;
      });
      return hasError;
    },
    copy() {
      navigator.clipboard.writeText(this.invitationUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
    close() {
      this.$router.push({
        name: "PageEducatorInstitutionTeacher",
        params: { id: this.$route.params.id },
      });
    },
    submit() {
      if (this.checkMobile()) {
        this.api.callbackError("Please check the form.");
      } else {
        this.api.params = this.$_.clone({
          data: this.teachers,
        });
        this.$api.fetch(this.api);
      }
    },
    next() {
      this.step++;
    },
    back() {
      this.step--;
    },
    getTeachers(val) {
      this.teachers = val;
      for (var i = 0; i < this.teachers.length; i++) {
        if (this.teachers[i].name == "") {
          this.teachers.splice(i, 1);
          i--;
        }
        if(this.teachers[i].mobile) {
          let mobile = this.teachers[i].mobile.replace(/[\s-]+/g, '');
          if (mobile.startsWith("0")) {
            this.$_.set( this.teachers[i], "mobile", "+6" +mobile);
          } else if (mobile.startsWith("6")) {
            this.$_.set(this.teachers[i], "mobile", "+" + mobile);
          } else if (mobile.startsWith("1")) {
            this.$_.set(this.teachers[i], "mobile", "+60" + mobile);
          } else if (mobile.startsWith("+")) {
            this.$_.set(this.teachers[i], "mobile", mobile);
          } else {
            this.$_.set(this.teachers[i], "mobile", "+60" + mobile);
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
.button-content {
  width: 300px !important;
}
</style>