<template>
  <div>
    <div style="min-height: 250px">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">Full Name</th>
              <th class="text-left">Mobile Number</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in teachers" :key="i">
              <td class="text-left">{{ i + 1 }}</td>
              <td class="text-left">{{ item.name }}</td>
              <td class="text-left">
                {{ item.mobile }}
                <div class=" red--text" v-if="checkMobile(item).isError">{{ checkMobile(item).message }}</div>
              </td>
              <td class="text-right">
                <v-btn @click="remove(i)" color="red" text>
                  Remove</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["callbackSuccess", "teachers"],
  data: () => ({
    isError : false
  }),
  created() {},
  methods: {
    checkMobile(item) {
      var message = { isError: false, message: null };
      if (!item.mobile ) {
        message.isError = true;
        message.message = "Mobile number is missing.";
        this.isError = true
        return message;
      }
      else if (!Number.isInteger(Number(item.mobile))) {
        message.isError = true;
        message.message = "Mobile number is invalid.";
        this.isError = true
        return message;
      }
      else if (item.mobile.length < 10 || item.mobile.length >= 15) {
        message.isError = true;
        message.message = "Mobile number shall have between 10 to 14 numbers.";
        this.isError = true
        return message;
      } 
      else{
        return message;
      }
    },
    remove(key) {
      this.teachers.splice(key, 1);
      if (this.teachers.length == 0) {
        this.$emit("close");
      }
    },

    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>